import scissors from "./pictures/scissors.svg";
import {Link} from "react-router-dom";

import "./PrivacyPolicy.css"

const PrivacyPolicy = () => {
    return <div className='privacyPolicy'>
        <header className="min-header" id="header"><Link to="/"><img src={scissors} alt="Logo Home"/>Kids
            Activities</Link></header>
        <div className="c36" style={{maxWidth: '900px', margin: '1rem auto'}}>
            <h1 className="c5 c24" id="h.khjp9k91d6il"><span className="c7 c38">DATENSCHUTZERKL&Auml;RUNG</span></h1>
            <p className="c26"><span className="c12">von Daily Kids Activities</span></p>
            <p className="c9"><span className="c7 c35">(Stand: 05.12.2021)</span></p>
            <p className="c22"><span className="c12">&nbsp;</span></p>
            <h2 className="c3 c5" id="h.1afz9q5dlzdr"><span className="c16 c7">&sect; 1 Information &uuml;ber die Erhebung personenbezogener Daten</span>
            </h2>
            <p className="c3"><span className="c12">(1) Im Folgenden informieren wir Sie &uuml;ber die Erhebung personenbezogener Daten bei Nutzung unserer Website. Personenbezogene Daten sind alle Daten, die auf Sie pers&ouml;nlich beziehbar sind, zum Beispiel Name, Adresse, E-Mail-Adressen, Nutzerverhalten.</span>
            </p>
            <p className="c3"><span className="c12">(2) Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (&ldquo;DSGVO&rdquo;) f&uuml;r die Datenverarbeitung auf dieser Website ist </span>
            </p>
            <p className="c0"><span className="c12">Daily Kids Activities</span></p>
            <p className="c0"><span className="c12">Vladislav Lisitsyn</span></p>
            <p className="c0"><span className="c12">Engadiner Stra&szlig;e 4</span></p>
            <p className="c0"><span className="c12">81475, M&uuml;nchen</span></p>
            <p className="c0"><span className="c12">Deutschland</span></p>
            <p className="c0"><span className="c12">E-Mail: info@daily-kids-activities.com</span></p>
            <p className="c3"><span className="c12">(3) Bei ihrer Kontaktaufnahme mit uns per E-Mail oder &uuml;ber ein Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten l&ouml;schen wir, nachdem die Speicherung f&uuml;r die Bearbeitung Ihrer Anfrage nicht mehr erforderlich ist, oder schr&auml;nken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.</span>
            </p>
            <p className="c3"><span className="c12">(4) Falls wir f&uuml;r einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zur&uuml;ckgreifen oder Ihre Daten f&uuml;r gewerbliche Zwecke nutzen m&ouml;chten, werden wir Sie untenstehend im Detail &uuml;ber die jeweiligen Vorg&auml;nge informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.</span>
            </p>
            <p className="c3"><span className="c12">&nbsp;</span></p>
            <h2 className="c3 c5" id="h.443evusv4stq"><span className="c16 c7">&sect; 2 Ihre Rechte</span></h2>
            <p className="c3"><span className="c12">(1) Sie haben gegen&uuml;ber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:</span>
            </p>
            <ul className="c8 lst-kix_by30n1u3dcag-0 start">
                <li className="c31 li-bullet-0"><span className="c12">Recht auf unentgeltliche Auskunft (Art. 15 DSGVO) insbesondere &uuml;ber Zweck, Empf&auml;nger und Dauer der Verarbeitung</span>
                </li>
                <li className="c31 li-bullet-0"><span className="c11">R</span><span className="c12">echt auf Berichtigung und L&ouml;schung (Art. 16 f. DSGVO)</span>
                </li>
                <li className="c31 li-bullet-0"><span className="c12">Recht auf Einschr&auml;nkung der Verarbeitung (Art. 18 DSGVO)</span>
                </li>
                <li className="c31 li-bullet-0"><span className="c12">Recht auf Widerspruch gegen die Verarbeitung (Art. 21 DSGVO)</span>
                </li>
                <li className="c31 li-bullet-0"><span className="c12">Recht auf Daten&uuml;bertragbarkeit (Art. 20 DSGVO) d.h. das Recht, Daten, die wir auf Grundlage einer Einwilligung des Nutzers oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern der Nutzer die direkte &Uuml;bertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</span>
                </li>
            </ul>
            <p className="c33"><span className="c12">(2) Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbeh&ouml;rde &uuml;ber die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren. Zust&auml;ndige Aufsichtsbeh&ouml;rde in datenschutzrechtlichen Fragen ist das Bayerische Landesamt f&uuml;r Datenschutzaufsicht, dessen Kontaktdaten folgendem Link entnommen werden k&ouml;nnen:</span>
            </p>
            <p className="c3"><span
                className="c10">https://www.bfdi.bund.de/SharedDocs/Adressen/DE/LfD/Bayern.html</span></p>
            <p className="c3 c15"><span className="c12"></span></p>
            <table className="c37">
                <tbody>
                <tr className="c20">
                    <td className="c13" colSpan={1} rowSpan={1}><h2 className="c3 c5" id="h.4j5yt4regdyh"><span
                        className="c7 c28">&sect; 3 </span><span className="c14 c7 c27">Recht auf Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten</span>
                    </h2>
                        <p className="c3"><span className="c7">(1) Falls Sie eine </span><span
                            className="c14 c7">Einwilligung</span><span
                            className="c6">&nbsp;zur Verarbeitung Ihrer Daten erteilt haben, k&ouml;nnen Sie diese jederzeit widerrufen. Ein solcher Widerruf beeinflusst die Zul&auml;ssigkeit der Verarbeitung Ihrer personenbezogenen Daten, nachdem Sie ihn gegen&uuml;ber uns ausgesprochen haben. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.</span>
                        </p>
                        <p className="c3"><span className="c7">(2) Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die </span><span
                            className="c14 c7">Interessenabw&auml;gung</span><span className="c6">&nbsp;st&uuml;tzen, insbesondere auf Art. 6 Abs. 1 S. 1 lit. f DSGVO, k&ouml;nnen Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur Erf&uuml;llung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei Aus&uuml;bung eines solchen Widerrufs bitten wir um Darlegung der Gr&uuml;nde, weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgef&uuml;hrt verarbeiten sollten. Wir pr&uuml;fen die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzw&uuml;rdigen Gr&uuml;nde aufzeigen, aufgrund derer wir die Verarbeitung fortf&uuml;hren.</span>
                        </p>
                        <p className="c3"><span className="c7">(3) Selbstverst&auml;ndlich k&ouml;nnen Sie der Verarbeitung Ihrer personenbezogenen Daten f&uuml;r Zwecke der </span><span
                            className="c7 c14">Werbung und Datenanalyse</span><span className="c6">&nbsp;jederzeit widersprechen.</span>
                        </p>
                        <p className="c34"><span className="c7">(4) Widerruf bzw. Widerspruch sind an die unter &sect; 1 Abs. 2 genannten Kontaktangaben zu richten.</span>
                        </p></td>
                </tr>
                </tbody>
            </table>
            <p className="c3 c15"><span className="c6"></span></p>
            <p className="c3 c15"><span className="c6"></span></p>
            <p className="c3 c15"><span className="c6"></span></p>
            <h2 className="c3 c5" id="h.ro433rph64ex"><span className="c16 c7">&sect; 4 Erhebung personenbezogener Daten bei Besuch unserer Website</span>
            </h2>
            <p className="c3"><span>(1) Bei der blo&szlig; informatorischen Nutzung der Website</span><span
                className="c12">&nbsp;erheben wir nur die personenbezogenen Daten, die Ihr Browser automatisch an unseren Server &uuml;bermittelt (sog. Server-Log-Dateien). Wenn Sie unsere Website betrachten m&ouml;chten, erheben wir die folgenden Daten, die f&uuml;r uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilit&auml;t und Sicherheit zu gew&auml;hrleisten:</span>
            </p>
            <p className="c3 c21"><span className="c12">- &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;IP-Adresse und Hostname des zugreifenden Rechners,</span>
            </p>
            <p className="c3 c21"><span className="c12">- &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Datum und Uhrzeit der Anfrage,</span>
            </p>
            <p className="c3 c21"><span className="c12">- &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Zeitzonendifferenz zur Greenwich Mean Time (GMT),</span>
            </p>
            <p className="c3 c21"><span className="c12">- &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Inhalt der Anforderung (konkrete Seite),</span>
            </p>
            <p className="c3 c21"><span
                className="c12">- &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Zugriffsstatus/HTTP-Statuscode,</span></p>
            <p className="c3 c21"><span className="c12">- &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;jeweils &uuml;bertragene Datenmenge,</span>
            </p>
            <p className="c3 c21"><span className="c12">- &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Website, von der die Anforderung kommt,</span>
            </p>
            <p className="c3 c21"><span className="c12">- &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Browser,</span></p>
            <p className="c3 c21"><span
                className="c12">- &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Betriebssystem und dessen Oberfl&auml;che,</span>
            </p>
            <p className="c3 c21"><span className="c12">- &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Sprache und Version der Browsersoftware.</span>
            </p>
            <p className="c3"><span className="c12">Rechtsgrundlage f&uuml;r diese Datenerhebung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.</span>
            </p>
            <p className="c15 c22"><span className="c12"></span></p>
            <h2 className="c5 c32" id="h.tknwvtk5cmx0"><span
                className="c16 c7">&sect; 5 Einbindung von YouTube-Videos</span></h2>
            <p className="c3">
                <span>(1) Wir haben YouTube-Videos in unser Online-Angebot eingebunden, die auf</span><span><a
                className="c1"
                href="https://www.google.com/url?q=http://www.youtube.com&amp;sa=D&amp;source=editors&amp;ust=1639429239667000&amp;usg=AOvVaw31JwdfB_Sv_MglxHKA4RmO">&nbsp;</a></span><span
                className="c10"><a className="c1"
                                   href="https://www.google.com/url?q=http://www.youtube.com&amp;sa=D&amp;source=editors&amp;ust=1639429239667000&amp;usg=AOvVaw31JwdfB_Sv_MglxHKA4RmO">http://www.youtube.com</a></span><span
                className="c12">&nbsp;gespeichert und von unserer Website aus direkt abspielbar sind. </span></p>
            <p className="c3"><span
                className="c12">Die Videos sind alle im &bdquo;erweiterten Datenschutzmodus&ldquo; eingebunden, d.h. dass keine Daten &uuml;ber Sie als Nutzer an YouTube &uuml;bertragen werden, wenn Sie die Videos nicht abspielen. Erst wenn Sie die Videos abspielen, werden die in Absatz 2 genannten Daten &uuml;bertragen. Auf diese Daten&uuml;bertragung haben wir keinen Einfluss.</span>
            </p>
            <p className="c3"><span>(2) Mit Ihrer Einwilligung erh&auml;lt YouTube die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Zudem werden die unter &sect; 4 dieser Erkl&auml;rung genannten Daten &uuml;bermittelt. Dies erfolgt unabh&auml;ngig davon, ob YouTube ein Nutzerkonto bereitstellt, &uuml;ber das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei YouTube nicht w&uuml;nschen, m&uuml;ssen Sie sich vor Aktivierung des &ldquo;Play&rdquo;-Buttons ausloggen. YouTube speichert Ihre Daten als Nutzungsprofile und nutzt sie f&uuml;r Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (selbst f&uuml;r nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks &uuml;ber Ihre Aktivit&auml;ten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Aus&uuml;bung dessen an YouTube richten m&uuml;ssen.</span>
            </p>
            <p className="c3"><span className="c12">(3) Wir nutzen das Einbinden von YouTube-Videos auf unserer Website zur ansprechenden Darstellung unserer Online-Angebote nur mit Ihrer Einwilligung. Rechtsgrundlage hierf&uuml;r ist Art. 6 Abs. 1 lit. a DSGVO.</span>
            </p>
            <p className="c3"><span>(4) Informationen des Drittanbieters: Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001. Weitere Informationen zu Zweck Umfang und Dauer der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten Sie in der Datenschutzerkl&auml;rung. Dort erhalten Sie auch weitere Informationen zu Ihren Rechten und Einstellungsm&ouml;glichkeiten zum Schutz Ihrer Privatsph&auml;re:</span><span><a
                className="c1"
                href="https://www.google.com/url?q=https://www.google.de/intl/de/policies/privacy&amp;sa=D&amp;source=editors&amp;ust=1639429239669000&amp;usg=AOvVaw22agYW-fLPYVq8kkS_vjBg">&nbsp;</a></span><span
                className="c10"><a className="c1"
                                   href="https://www.google.com/url?q=https://www.google.de/intl/de/policies/privacy&amp;sa=D&amp;source=editors&amp;ust=1639429239669000&amp;usg=AOvVaw22agYW-fLPYVq8kkS_vjBg">https://www.google.de/intl/de/policies/privacy</a></span><span>. </span><span>Damit willigen Nutzer zugleich gem. Art. 49 Abs. 1 lit. a DSGVO ein, dass ihre Daten in den USA verarbeitet werden k&ouml;nnen. </span><span
                className="c11">Wir weisen darauf hin, dass das Datenschutzniveau in den USA nicht den Datenschutzanforderungen der Europ&auml;ischen Union gen&uuml;gt. Daten des Nutzers k&ouml;nnen dort</span><span
                className="c12">&nbsp;m&ouml;glicherweise auch ohne Rechtsbehelfsm&ouml;glichkeiten verarbeitet werden. Ein Zugriff durch US-Beh&ouml;rden kann dabei nicht ausgeschlossen werden. </span>
            </p>
            <p className="c3 c15"><span className="c12"></span></p>
            <h2 className="c3 c5" id="h.jelr7fezxcin"><span
                className="c16 c7">&sect; 6 Nutzung von Plausible Analytics</span></h2>
            <p className="c25"><span
                className="c12">(1) Wir nutzen den Webanalysedienst &bdquo;Plausible Analytics&ldquo; um unser Angebot fortlaufend technisch und inhaltlich zu optimieren. </span>
            </p>
            <p className="c19"><span className="c12">Plausible erfasst zu diesem Zweck unter anderem die folgenden Informationen: Datum und Uhrzeit ihres Besuches, Titel und URL der besuchten Seiten, eingehende Links, das Land in dem Sie sich befinden und den User Agent Ihres Brwosers. Plausible verwendet und speichert keine Cookies auf Ihrem Endger&auml;t. S&auml;mtliche personenbezogenen Daten werden ausschlie&szlig;lich vollst&auml;ndig anonymisiert in Form eines sogenannten Hashes gespeichert. Ein Hash ist eine Verschl&uuml;sselung von Daten, die nicht umkehrbar ist, also nicht &bdquo;entschl&uuml;sselt&ldquo; werden kann. Auf diese Weise k&ouml;nnen wir Ihren Besuch analysieren, ohne personenbezogenen Daten in einer Form zu speichern, die von uns, Plausible oder sonstige Dritte lesbar ist.</span>
            </p>
            <p className="c19"><span
                className="c12">(2) Informationen des Drittanbieters: Plausible Insights O&Uuml;, V&auml;striku tn 2, 50403, Tartu, Estonia.</span>
            </p>
            <p className="c19 c15"><span className="c12"></span></p>
            <h2 className="c5 c19" id="h.86ij5cbv9qyq"><span className="c16 c7">&sect; 7 Auftragsverarbeitung durch Firebase Hosting</span>
            </h2>
            <p className="c18"><span
                className="c11">Unsere Website wird mithilfe des Dienstes Firebase Hosting der Google Ireland Ltd.</span><span
                className="c7 c11">&nbsp;</span><span className="c11">gehostet. Die Google Ireland Ltd.</span><span
                className="c7 c11">&nbsp;</span><span
                className="c4">speichert in unserem Auftrag alle bei der Nutzung unserer Website erhobenen personenbezogenen Daten als Auftragsdatenverarbeiter im Sinne des Art. 28 DSGVO. Die Verarbeitung dieser Daten durch die Google Ireland Ltd. findet, laut deren Angaben ausschlie&szlig;lich in der Bundesrepublik Deutschland, in Mitgliedstaaten der Europ&auml;ischen Union oder Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum statt.</span>
            </p>
            <p className="c23"><span className="c11">Die &Uuml;bermittlung personenbezogener Daten durch uns an die Google Ireland Ltd. erfordert jedes Mal, dass die Voraussetzungen mindestens einer in dieser Datenschutzerkl&auml;rung genannten Rechtsgrundlagen erf&uuml;llt sind. </span>
            </p>
            <p className="c3"><span className="c12">&nbsp;</span></p>
            <h2 className="c3 c5" id="h.rj8bfwobz23o"><span className="c7 c16">&sect; 8 Sicherheit Ihrer Daten</span>
            </h2>
            <p className="c3"><span className="c12">Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils h&ouml;chsten Verschl&uuml;sselungsstufe, die von Ihrem Browser unterst&uuml;tzt wird. Ob eine einzelne Seite unseres Internetauftrittes verschl&uuml;sselt &uuml;bertragen wird, erkennen Sie an der geschlossenen Darstellung des Sch&uuml;ssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.</span>
            </p>
            <p className="c3"><span className="c12">Wir bedienen uns im &Uuml;brigen geeigneter technischer und organisatorischer Sicherheitsma&szlig;nahmen, um Ihre Daten gegen zuf&auml;llige oder vors&auml;tzliche Manipulationen, teilweisen oder vollst&auml;ndigen Verlust, Zerst&ouml;rung oder gegen den unbefugten Zugriff Dritter zu sch&uuml;tzen. Unsere Sicherheitsma&szlig;nahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</span>
            </p>
            <p className="c3"><span className="c12">&nbsp;</span></p>
            <h2 className="c3 c5" id="h.y2ad87d6y2bf"><span
                className="c16 c7">&sect; 9 &Auml;nderungen dieser Datenschutzerkl&auml;rung</span></h2>
            <p className="c3"><span className="c12">Diese Datenschutzerkl&auml;rung ist auf dem Stand vom 05.12.2021. Durch die Weiterentwicklung unserer Website und die Implementierung neuer Technologien zur Verbesserung unseres Services, oder aufgrund ge&auml;nderter gesetzlicher bzw. beh&ouml;rdlicher Vorgaben, k&ouml;nnen &Auml;nderungen dieser Datenschutzerkl&auml;rung erfolgen. Daher empfehlen wir Ihnen, sich diese Datenschutzerkl&auml;rung von Zeit zu Zeit erneut durchzulesen. Die jeweils aktuelle Fassung kann jederzeit auf dieser Website von Ihnen abgerufen und ausgedruckt werden.</span>
            </p>
            <p className="c15 c30"><span className="c12"></span></p>
        </div>
    </div>
}

export default PrivacyPolicy