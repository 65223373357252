import scissors from "./pictures/scissors.svg";
import {Link} from "react-router-dom";

import "./Imprint.css"

const Imprint = () => {
    return <div className='impressum'>
        <header className="min-header" id="header"><Link to="/"><img src={scissors} alt="Logo Home"/>Kids
            Activities</Link></header>
        <div className="d18" style={{maxWidth: '900px', margin: '1rem auto'}}>
            <h1 className="d20" id="h.l87etcdsnpkf"><span className="d11">IMPRESSUM</span></h1>
            <p className="d15"><span className="d4">von Daily Kids Activities</span></p>
            <p className="d6"><span className="d14">(Stand: 05.12.2021)</span></p>
            <p className="d0"><span className="d7"></span></p>
            <h2 className="d13" id="h.c19lvfvf7mzu"><span
                className="d7">Pflichtangaben gem&auml;&szlig; &sect; 5 TMG</span></h2>
            <p className="d0"><span className="d4"></span></p>
            <p className="d9"><span className="d4">Daily Kids Activities</span></p>
            <p className="d9"><span className="d4">Vladislav Lisitsyn</span></p>
            <p className="d9"><span className="d4">Engadiner Stra&szlig;e 4</span></p>
            <p className="d9"><span className="d4">81475, M&uuml;nchen</span></p>
            <p className="d9"><span className="d4">Deutschland</span></p>
            <p className="d0"><span className="d4"></span></p>
            <p className="d9"><span>E-Mail: </span>
                <a href="mailto:info@daily-kids-activities.com">info@daily-kids-activities.com</a>
            </p>
            <p className="d0"><span className="d4"></span></p>
            <h2 className="d13" id="h.a4jh49xqvyjr"><span className="d7">Streitschlichtungsplattform der EU</span></h2>
            <p className="d1"><span>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:</span>
                &nbsp;<span className="d8"><a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a></span><span
                className="d8">&nbsp;</span></p>
            <p className="d1"><span>&nbsp;</span></p>
            <p className="d1"><span className="d4">Wir sind nicht verpflichtet und nicht bereit, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</span>
            </p>
            <p className="d1 d17"><span className="d4"></span></p>
            <h2 className="d13" id="h.77oacpkaeeiw"><span className="d7">Bildquellennachweis</span></h2>
            <p className="d1">
                <span>Wir sind Inhaber der Rechte an allen Bildern und Fotos auf unserer Website.&nbsp;Alle Bilder und Fotos d&uuml;rfen weder kopiert noch in sonstiger Weise verwertet werden. </span>
            </p>
            <p className="d0"><span className="d4"></span></p>
            <p className="d10"><span className="d14"></span></p>
            <p className="d17 d19"><span className="d4"></span></p>
        </div>
    </div>
}

export default Imprint