import {useTranslation} from "react-i18next";
import {availableLanguages} from "./i18n";
import {Timestamp} from "firebase/firestore";
import {Activity} from "./Types";
import pencil1 from "./pictures/pencil-1.svg";
import pencil from "./pictures/pencil.svg";
import balloon from "./pictures/balloon.svg";
import pencil2 from "./pictures/pencil-2.svg";
import React, {useEffect, useState} from "react";
import ActivityBox from "./ActivityBox";
import {fetchNextDailyActivities, fetchTodayActivities, updateStandardFieldsOf} from "./ActivityService";
import familyAcivity from "./pictures/family-activities.svg";


export function createTimestampOfTodayAt0AM() {
    return Timestamp.fromDate(new Date(new Date().setHours(0, 0, 0, 0)));
}

const DailyKidsActivities = () => {
    const {t, i18n} = useTranslation()
    const langSubstring = i18n.language ? i18n.language.substring(0, 2) : 'en';
    const language = availableLanguages.indexOf(langSubstring) > -1 ? langSubstring : 'en';

    document.documentElement.setAttribute('lang', language);

    const [activities, setActivities] = useState<Activity[]>([]);
    useEffect(() => {
        async function fetchActivitiesForToday() {
            const todayActivities = await fetchTodayActivities();

            if (todayActivities.length > 0) {
                setActivities(todayActivities);
                return;
            }

            const nextDailyActivities = await fetchNextDailyActivities();
            const updatedTodayActivities = updateStandardFieldsOf(nextDailyActivities);
            setActivities(updatedTodayActivities);
        }

        fetchActivitiesForToday().catch(reason => console.error(reason));
    }, [])

    function parallax(mouseX: number, mouseY: number, distortion: number, element: string) {
        let elem = document.getElementById(element);
        if (elem) {
            elem.style.marginLeft = mouseX * distortion + "rem";
            elem.style.marginTop = mouseY * distortion + "rem";
        }
    }

    document.addEventListener("DOMContentLoaded", () => {
        document.onmousemove = function (e) {
            let x = e.clientX;
            let y = e.clientY;
            parallax(x, y, 0.001, 'decoration-pencil-1');
            parallax(x, y, -0.0005, 'decoration-pencil-2');
            parallax(x, y, 0.0015, 'decoration-balloon-1');
            parallax(x, y, 0.0005, 'decoration-balloon-2');

            // @ts-ignore
            document.getElementById('header').style.paddingTop = y * 0.001 + "rem";
            // @ts-ignore
            document.getElementById('header').style.marginBottom = -y * 0.001 + "rem";

        };
    });

    return (
        <>
            <header id="header">
                <img src={familyAcivity} alt="a family sitting on the floor playing games"/>
                <h1>Kids Activities</h1>
                <h2>{t('homePageDescription')}</h2>
            </header>
            <div className="decoration decoration-left">
                <img src={pencil1} alt="pencil drawing" id="decoration-pencil-1"/>
                <img src={pencil} alt="pencil drawing" id="decoration-pencil-2"/>
            </div>

            <div className="decoration decoration-right">
                <img src={balloon} alt="balloon drawing" id="decoration-balloon-1"/>
            </div>

            <div className="decoration decoration-right-top xs-hidden">
                <div id="decoration-balloon-2"/>
            </div>

            <main>
                <ActivityBox index={1} activity={activities.length > 0 && activities[0] || undefined}
                             language={language}/>
                <ActivityBox index={2} activity={activities.length > 1 && activities[1] || undefined}
                             language={language} decoratorImages={[pencil1]}/>
                <ActivityBox index={3} activity={activities.length > 2 && activities[2] || undefined}
                             language={language} decoratorImages={[pencil, pencil2]}/>
            </main>
        </>
    );
}

export default DailyKidsActivities