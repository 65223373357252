/**
 * To find your Firebase config object:
 *
 * 1. Go to your [Project settings in the Firebase console](https://console.firebase.google.com/project/_/settings/general/)
 * 2. In the "Your apps" card, select the nickname of the app for which you need a config object.
 * 3. Select Config from the Firebase SDK snippet pane.
 * 4. Copy the config object snippet, then add it here.
 */
const config = {
    apiKey: "AIzaSyB9neQTnEBxxmintaAMwbZrQMIeCyCM98U",
    authDomain: "daily-kids-activities.firebaseapp.com",
    projectId: "daily-kids-activities",
    storageBucket: "daily-kids-activities.appspot.com",
    messagingSenderId: "515986088171",
    appId: "1:515986088171:web:8772a583309b3697565eae"
};

export function getFirebaseConfig() {
    if (!config || !config.apiKey) {
        throw new Error('No Firebase configuration object provided.\n' +
            'Add your web app\'s configuration object to firebase-config.js');
    } else {
        return config;
    }
}
