import scissors from "./pictures/scissors.svg";
import {Link} from "react-router-dom";

import "./TermsOfService.css"

const TermsOfService = () => {
    return <div className='termsOfService'>
        <header className="min-header" id="header"><Link to="/"><img src={scissors} alt="Logo Home"/>Kids
            Activities</Link></header>
        <div className="t13" style={{maxWidth: '900px', margin: '1rem auto'}}>
            <h1 className="t7" id="h.l87etcdsnpkf"><span className="t10">NUTZUNGSBEDINGUNGEN</span></h1>
            <p className="t5"><span className="t0">von Daily Kids Activities</span></p>
            <p className="t12"><span className="t4">(Stand: 05.12.2021)</span></p>
            <h2 className="t1" id="h.hj3e6srkm62n"><span className="t3">&sect; 1 Haftungsausschluss</span></h2>
            <p className="t11"><span className="t0">Soweit gesetzlich zul&auml;ssig, sind wir nicht verantwortlich, wenn die auf dieser Website zur Verf&uuml;gung gestellten Informationen nicht korrekt, vollst&auml;ndig oder aktuell sind. Die Inhalte unserer Website dienen nur der allgemeinen Information und sollten nicht als alleinige Grundlage f&uuml;r Entscheidungen herangezogen werden, ohne dass Sie sich selbst ausf&uuml;hrlich Informiert haben. Das Vertrauen in die Inhalte unserer Website erfolgt auf eigenes Risiko. </span>
            </p>
            <p className="t9"><span className="t3"></span></p>
            <h2 className="t2" id="h.8wz7dfkzqo2g"><span className="t3">&sect; 2 Verf&uuml;gbarkeit des Dienstes</span>
            </h2>
            <p className="t6"><span className="t0">Wir streben eine umfassende Verf&uuml;gbarkeit der angebotenen Informationen an. F&uuml;r Ausf&auml;lle unserer Website schlie&szlig;en wir jedoch jede Verantwortlichkeit aus. Ein Anspruch auf eine jederzeitige Verf&uuml;gbarkeit kann nicht gew&auml;hrt werden. F&uuml;r internet- oder netzbedingte Ausfallzeiten oder sonstige Probleme, die nicht in unserem Einflussbereich liegen, wie beispielsweise h&ouml;here Gewalt oder Handlungen Dritter, sind wir nicht verantwortlich.</span>
            </p>
            <p className="t8"><span className="t0"></span></p>
            <h2 className="t2" id="h.fn4bzjmt695h"><span className="t3">&sect; 3 &Auml;nderungen des Dienstes</span>
            </h2>
            <p className="t11"><span>Wir behalten uns das Recht vor, Teile unserer Website oder die Website als Ganzes jederzeit zu ver&auml;ndern, zu erg&auml;nzen, zu l&ouml;schen oder die Ver&ouml;ffentlichung zeitweise oder endg&uuml;ltig einzustellen. Wir sind nicht verpflichtet, Informationen auf unserer Website zu aktualisieren. </span>
            </p>
        </div>
    </div>
}

export default TermsOfService