import {initializeApp} from "firebase/app";
import {getFirebaseConfig} from "./firebase-config";
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";

export const app = initializeApp(getFirebaseConfig());
export const db = getFirestore();
export const auth = getAuth();
export const isEmulatorRunning = process.env.REACT_APP_IS_EMULATOR;
if (isEmulatorRunning) {
    connectFirestoreEmulator(db, 'localhost', 8080);
}