import React from "react";
import {Activity} from "./Types";
import {useTranslation} from "react-i18next";

interface Props {
    index: number,
    language: string,
    activity?: Activity,
    decoratorImages?: string[]
}

const ActivityBox = ({index, activity, language, decoratorImages}: Props) => {
    const {t} = useTranslation()

    const openActivityInNewTabFunction = () => {
        // @ts-ignore
        const searchUrlForActivity = activity && `https://www.google.com/search?q=${activity[language] + t('kids')}`;
        activity && window.open(
            searchUrlForActivity, "_blank", "noopener, noreferrer");
    }

    return (
        <section className="card" id={"activity" + index} onClick={openActivityInNewTabFunction}>
            <div className="activity-decoration">
                {decoratorImages?.map((image, index) => <img key={"image" + index} src={image} alt="pencil"/>)}
            </div>
            <h3 className="activity-header"><span>Activity {index}</span></h3>
            <div className="description">
                <p>{// @ts-ignore
                    activity && activity[language]}</p>
            </div>
            {activity?.imageLink && <div className="activity-picture">
                <div className="activity-picture-frame">
                    <img src={activity.imageLink} alt={activity.alt}/>
                </div>
            </div>}
        </section>);
}

export default ActivityBox